import styled from "styled-components";
import { Theme } from "../../../theme";

const width = '500px';
const height = 50;

export const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 90%;
  margin: ${height}px auto 0 auto;
  background-color: transparent;
  border: 1px solid #ccc
`;

export const FormContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: ${width};
  height: calc(100vh - ${height * 3}px);
  background-color: rgba(0,0,0,0.4);
  font-family: 'Open Sans', sans-serif;
  color: white;
  
  @media(max-width: 1400px){
    width: 40%;
  }
  
  @media(max-width: 1000px){
    width: 100%;
    height: auto;
  }
`;

export const SignatureContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: calc(100% - ${width});
  height: calc(100vh - ${height * 3}px);
  background-color: white;
  justify-content: center;
  align-items: center;
  
  @media(max-width: 1400px){
    width: 60%;
  }

  @media(max-width: 1000px){
    width: 100%;
    height: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${Theme.darkred};
  height: ${height+'px'};
  border-radius: 3px;
  border: 1px solid ${Theme.black}
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-left: 25px;
  color: ${Theme.yellow};
`;

export const Button = styled.button`
  
    @property --colorPrimary {
      syntax: '<color>';
      initial-value:  ${Theme.yellow};
      inherits: false;
    }
    
    @property --colorSecondary {
      syntax: '<color>';
      initial-value: ${Theme.yellow};
      inherits: false;
    }
    
    @property --colorThird {
      syntax: '<color>';
      initial-value: rgba(255,255,255,0.1);
      inherits: false;
    }
      
  padding: 13px 30px;
  border: 1px solid ${Theme.black};
  color: ${Theme.darkred};
  border-radius: 5px;
  background: radial-gradient(circle, var(--colorPrimary) 0%, var(--colorSecondary) 85%) no-repeat;
  transition: --colorPrimary 0ms, --colorSecondary 0ms;
      
  &:hover {
    --colorPrimary: ${Theme.darkred};
    --colorSecondary: ${Theme.darkred};
    color: ${Theme.yellow};
  }
      
  &:active{
    background: radial-gradient(circle, var(--colorPrimary) 13%, var(--colorThird) 32%, var(--colorSecondary)  39%, var(--colorPrimary) 100%);
    --colorPrimary: rgba(255,255,255,0.1);
    --colorSecondary: rgba(255,255,255,0.3);
    --colorThird: rgba(255,255,255,0.2);
    transition: --colorPrimary 0ms, --colorSecondary 300ms, --colorThird 500ms;
    color: ${Theme.yellow};
  }
`;

export const ButtonGroup = styled.div`
  margin-right: 25px;
  height: 95%;
  padding: 1px;
`;