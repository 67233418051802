import Signature from "./pages";
import {GlobalStyles} from "./global-style";

function App() {
  return (
    <>
      <GlobalStyles/>
      <Signature />
    </>
  );
}

export default App;
