import { createGlobalStyle } from 'styled-components';
import background from './images/bg.jpeg'
export const GlobalStyles = createGlobalStyle`
  *, *::after, *::before {
    box-sizing: border-box;
  }
  
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: 16px;
    background: black url(${background}) center / cover no-repeat;
    font-family: 'Roboto', sans-serif;
  }
`;