import React from "react";
import {Container, FormContainer, Header, SignatureContainer, Button, Title, ButtonGroup} from "./styles/card.css";

export default function Card({children, ...restProps}){

    return(<Container {...restProps}>{children}</Container>);
}

Card.Header = function ({children, ...restProps}){

    return(<Header {...restProps}>{children}</Header>)
}

Card.FormContainer = function ({children, ...restProps}){

    return(<FormContainer {...restProps}>{children}</FormContainer>)
}

Card.SignatureContainer = function ({children, ...restProps}){

    return(<SignatureContainer {...restProps}>{children}</SignatureContainer>)
}

Card.Button = function ({ children, ...restProps}){

    return(<Button {...restProps}>{children}</Button>)
}

Card.ButtonGroup = function ({children, ...restProps}){

    return(<ButtonGroup {...restProps}>{children}</ButtonGroup>)
}

Card.Title = function ({children, ...restProps}){

    return(<Title {...restProps}>{children}</Title>)
}