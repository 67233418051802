import React from "react";
import { Container, Input, Label } from "./styles/form.css";

export default function Form({children, ...restProps}){

    return(<Container {...restProps}>{children}</Container>)
}

Form.Input = function ({label, type, value, onChange, ...restProps}){

    return(
        <Label>
            {label}
            <Input placeholder={label} type={type} value={value} onChange={( e ) => onChange(e.target.value)} {...restProps} />
        </Label>
    );
}