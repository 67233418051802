import React, {createRef, Suspense, useState} from "react";
import {Card} from "../components";

const FormContainer = React.lazy(() => import('../container/formContainer'));
const SignatureContainer = React.lazy(() => import('../container/signatureContainer'));

export default function Signature(){
    const copyBoxRef = createRef();
    const [name, setName] = useState('John Doe');
    const [jobTitle, setJobTitle] = useState('Travel Agent');
    const [mobile, setMobile] = useState('XXX-XXX-XXX');
    const [tel, setTel] = useState('XX XXX-XX-XX');
    const [mail, setMail] = useState('john.doe@topowewakacje.pl');

    const copyOnClick = () => {
        const copyBoxElement = copyBoxRef.current;
        console.log(copyBoxElement);
        copyBoxElement.contentEditable = true;
        copyBoxElement.focus();
        document.execCommand('selectAll');
        document.execCommand('copy');
        copyBoxElement.contentEditable = false;
        getSelection().empty();
    }

    return(
            <Card>
                <Card.Header>
                    <Card.Title>Signature</Card.Title>
                    <Card.ButtonGroup>
                        <Card.Button onClick={() => copyOnClick()}>Copy</Card.Button>
                    </Card.ButtonGroup>
                </Card.Header>
                <Card.FormContainer>
                    <Suspense fallback={<div>Wczytywanie...</div>}>
                        <FormContainer
                            name={name}
                            setName={setName}
                            jobTitle={jobTitle}
                            setJobTitle={setJobTitle}
                            mobile={mobile}
                            setMobile={setMobile}
                            tel={tel}
                            setTel={setTel}
                            mail={mail}
                            setMail={setMail}
                        />
                    </Suspense>
                </Card.FormContainer>
                <Card.SignatureContainer>
                    <Suspense fallback={<div>Wczytywanie...</div>}>
                        <SignatureContainer
                            name={name}
                            jobTitle={jobTitle}
                            mobile={mobile}
                            tel={tel}
                            mail={mail}
                            copyBoxRef={copyBoxRef}
                            language={'PL'}
                        />
                    </Suspense>
                </Card.SignatureContainer>
            </Card>
    );
}